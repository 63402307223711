<template>
  <div class="suppliers-container">
    <div class="search-box">
      <el-input v-model="searchName" placeholder="请输入供应商名称" style="width: 200px" @keyup.enter.native="handleSearch">
        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
      </el-input>
    </div>

    <el-table :data="suppliers" border style="width: 100%">
      <el-table-column label="供应商信息" width="250">
        <template slot-scope="scope">
          <el-tooltip placement="top-start" :content="`${scope.row.supplier_name}\n${scope.row.supplier_short_name}`">
            <div class="ellipsis-cell">
              <div class="main-text">{{ scope.row.supplier_name }}</div>
              <div class="sub-text">{{ scope.row.supplier_short_name }}</div>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="register_date" label="登记日期" width="120">
        <template slot-scope="scope">
          {{ scope.row.register_date ? scope.row.register_date.split(' ')[0] : '' }}
        </template>
      </el-table-column>
      <el-table-column label="注册信息">
        <template slot-scope="scope">
          <el-tooltip placement="top-start" :content="`${scope.row.registered_address}\n${scope.row.registered_phone}\n${scope.row.credit_code}`">
            <div class="ellipsis-cell">
              <div class="main-text">{{ scope.row.registered_address }}</div>
              <div class="sub-text">{{ scope.row.registered_phone }}</div>
              <div class="sub-text">{{ scope.row.credit_code }}</div>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="modify_date" label="更新日期" width="120">
        <template slot-scope="scope">
          {{ scope.row.register_date ? scope.row.modify_date.split(' ')[0] : '' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="showDetail(scope.row)">详情</el-button>
          <el-button type="text" @click="showPolicies(scope.row)">政策</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 20, 30]" :page-size="pageSize" :total="total" layout="total, sizes, prev, pager, next">
      </el-pagination>
    </div>

    <!-- 详情抽屉 -->
    <el-drawer title="供应商详情" :visible.sync="drawerVisible" direction="rtl" size="50%">
      <div class="drawer-content" v-if="currentSupplier">
        <el-descriptions :column="1" border>
          <el-descriptions-item label="供应商名称">{{ currentSupplier.supplier_name }}</el-descriptions-item>
          <el-descriptions-item label="供应商简称">{{ currentSupplier.supplier_short_name }}</el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码">{{ currentSupplier.credit_code }}</el-descriptions-item>
          <el-descriptions-item label="成立日期">{{ currentSupplier.establishment_date }}</el-descriptions-item>
          <el-descriptions-item label="注册资金">{{ currentSupplier.registered_capital }}</el-descriptions-item>
          <el-descriptions-item label="注册地址">{{ currentSupplier.registered_address }}</el-descriptions-item>
          <el-descriptions-item label="联系地址">{{ currentSupplier.contact_address }}</el-descriptions-item>
          <el-descriptions-item label="登记人员">{{ currentSupplier.registrant }}</el-descriptions-item>
          <el-descriptions-item label="人员编号">{{ currentSupplier.personnel_number }}</el-descriptions-item>
          <el-descriptions-item label="岗位">{{ currentSupplier.position }}</el-descriptions-item>
          <el-descriptions-item label="部门">{{ currentSupplier.department }}</el-descriptions-item>
          <el-descriptions-item label="币种">{{ currentSupplier.currency }}</el-descriptions-item>
          <el-descriptions-item label="注册电话">{{ currentSupplier.registered_phone }}</el-descriptions-item>
          <el-descriptions-item label="登记日期">{{ currentSupplier.register_date }}</el-descriptions-item>
          <el-descriptions-item label="更新时间">{{ currentSupplier.modify_date }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>

    <!-- 政策抽屉 -->
    <el-drawer title="供应商政策" :visible.sync="policiesDrawerVisible" direction="rtl" size="70%">
      <div class="drawer-content" v-if="currentSupplier">
        <div class="policy-header">
          <h3>{{ currentSupplier.supplier_name }} 的政策列表</h3>
        </div>

        <el-table :data="policies" border style="width: 100%">
          <el-table-column prop="policy_number" label="政策编号" width="120"></el-table-column>
          <el-table-column prop="policy_short_name" label="政策简称" width="150"></el-table-column>
          <el-table-column prop="year" label="年度" width="80"></el-table-column>
          <el-table-column prop="supplier_product" label="供应商产品" width="120"></el-table-column>
          <el-table-column label="有效期" width="200">
            <template slot-scope="scope">
              {{ scope.row.start_date }} 至 {{ scope.row.end_date }}
            </template>
          </el-table-column>
          <el-table-column prop="rebate_percentage" label="返点比例" width="100"></el-table-column>
          <el-table-column prop="rebate_settlement_method" label="结算方式" width="120"></el-table-column>
          <el-table-column prop="payment_terms" label="账期" width="100"></el-table-column>
          <el-table-column prop="remarks" label="备注"></el-table-column>
        </el-table>

        <div class="policy-pagination">
          <el-pagination @size-change="handlePolicySizeChange" @current-change="handlePolicyCurrentChange" :current-page="policyPage" :page-sizes="[10, 20, 30]" :page-size="policyPageSize" :total="policyTotal" layout="total, sizes, prev, pager, next">
          </el-pagination>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getSuppliers, getSupplierPolicies } from '@/api'

export default {
  name: 'Suppliers',
  data () {
    return {
      searchName: '',
      suppliers: [],
      page: 1,
      pageSize: 10,
      total: 0,
      drawerVisible: false,
      currentSupplier: null,
      policiesDrawerVisible: false,
      policies: [],
      policyPage: 1,
      policyPageSize: 10,
      policyTotal: 0
    }
  },
  created () {
    this.fetchSuppliers()
  },
  methods: {
    async fetchSuppliers () {
      try {
        const res = await getSuppliers({
          name: this.searchName,
          page: this.page,
          page_size: this.pageSize
        })
        if (res.code === 200) {
          this.suppliers = res.data.list
          this.total = res.data.pagination.total
          this.page = res.data.pagination.page
          this.pageSize = res.data.pagination.page_size
        } else {
          this.$message.error(res.sub_msg || res.msg || '获取供应商列表失败')
        }
      } catch (error) {
        console.error(error)
        this.$message.error('获取供应商列表失败')
      }
    },
    handleSearch () {
      this.page = 1 // 搜索时重置页码
      this.fetchSuppliers()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchSuppliers()
    },
    handleCurrentChange (val) {
      this.page = val
      this.fetchSuppliers()
    },
    showDetail (row) {
      this.currentSupplier = row
      this.drawerVisible = true
    },
    showPolicies (row) {
      this.currentSupplier = row
      this.policiesDrawerVisible = true
      this.policyPage = 1
      this.fetchSupplierPolicies()
    },
    async fetchSupplierPolicies () {
      try {
        const res = await getSupplierPolicies({
          supplier_id: this.currentSupplier.id,
          page: this.policyPage,
          page_size: this.policyPageSize
        })
        if (res.code === 200) {
          this.policies = res.data.list
          this.policyTotal = res.data.pagination.total
          this.policyPage = res.data.pagination.page
          this.policyPageSize = res.data.pagination.page_size
        } else {
          this.$message.error(res.sub_msg || res.msg || '获取供应商政策失败')
        }
      } catch (error) {
        console.error(error)
        this.$message.error('获取供应商政策失败')
      }
    },
    handlePolicySizeChange (val) {
      this.policyPageSize = val
      this.fetchSupplierPolicies()
    },
    handlePolicyCurrentChange (val) {
      this.policyPage = val
      this.fetchSupplierPolicies()
    }
  }
}
</script>

<style scoped>
.suppliers-container {
  padding: 20px;
}

.search-box {
  margin-bottom: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.drawer-content {
  padding: 20px;
}

.registered_phone {
  font-size: 12px;
  color: #909399;
}

.ellipsis-cell {
  width: 100%;
}

.main-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sub-text {
  font-size: 12px;
  color: #909399;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.policy-header {
  margin-bottom: 20px;
}

.policy-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>